import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/styles.css';

import AxipaysUpper_logo from '../media/logo-axipays.png';
import certificates from '../media/certificates.png';
import Icon from '../media/icons';

function Sidebar() {
    const location = useLocation(); 
    const [activeItem, setActiveItem] = useState("Introduction");
    const [isSidebarOpen, setSidebarOpen] = useState(false); // Sidebar visibility state

    const routeToItemMap = {
        "/": "Introduction",
        "/paymentIntegration": "Integration Methods",
        "/s2sIntegration": "Server to server integration",
        "/iframeIntegration": "Iframe integration",
        "/errorHandling": "Error Handling",
        "/errorCodes": "Error Codes",
        "/responses": "Responses",
        "/dashboardOverview": "Overview",
        "/dashboard": "Dashboard",
        "/extractCreds": "Extract creds",
        "/setWebhook": "Set webhook",
        "/monitorTransactions": "Monitor transactions",
    };

    useEffect(() => {
        const itemName = routeToItemMap[location.pathname] || "Introduction";
        setActiveItem(itemName);
    }, [location.pathname]);

    const sidebarItems = [
        {
            section: "Get started",
            items: [{ name: "Introduction", link: "/" }],
        },
        {
            section: "Payment Integration",
            items: [
                {
                    name: "Integration Methods",
                    link: "/paymentIntegration",
                    submenu: [
                        { name: "Server to server integration", link: "/s2sIntegration" },
                        { name: "Iframe integration", link: "/iframeIntegration" },
                    ],
                },
                {
                    name: "Error Handling",
                    link: "/errorHandling",
                    submenu: [
                        { name: "Error Codes", link: "/errorCodes" },
                        { name: "Responses", link: "/responses" },
                    ],
                },
            ],
        },
        {
            section: "Dashboard Tour",
            items: [
                {
                    name: "Overview",
                    link: "/dashboardOverview",
                    submenu: [
                        { name: "Dashboard", link: "/dashboard" },
                        { name: "Extract creds", link: "/extractCreds" },
                        { name: "Set webhook", link: "/setWebhook" },
                        { name: "Monitor transactions", link: "/monitorTransactions" },
                    ],
                },
            ],
        },
    ];

    const SidebarItem = ({ item }) => (
        <div className="sidebar-item-wrapper">
            <Link
                to={item.link}
                className={`sidebar-item ${activeItem === item.name ? "active" : ""}`}
                onClick={() => setSidebarOpen(false)} 
            >
                <span className="items">{item.name}</span>
            </Link>
            {item.submenu && (
                <div className="submenu always-visible">
                    {item.submenu.map((subItem) => (
                        <Link
                            key={subItem.name}
                            to={subItem.link}
                            className={`submenu-item ${activeItem === subItem.name ? "active" : ""}`}
                            onClick={() => setSidebarOpen(false)} // Close sidebar on sub-item click
                        >
                            <span>{subItem.name}</span>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );

    return (
        <>
            {/* Toggle button */}
            <div
                className="sidebar-toggle"
                onClick={() => setSidebarOpen(!isSidebarOpen)}
            >
                {isSidebarOpen ? <Icon name="arrow_left" height={20} width={20} color='#003366'></Icon> :  <Icon name="arrow_right"  height={20} width={20} color='#003366'></Icon>}
            </div>

            <div className={`api-sidebar ${isSidebarOpen ? "open" : ""}`}>
                <div className="sidebar-header">
                    <img src={AxipaysUpper_logo} alt="Axipays full logo" />
                </div>

                <div className="sidebar-body">
                    <div className="scrollable-content">
                        {sidebarItems.map((section) => (
                            <div key={section.section} className="sidebar-section">
                                <p className="section-title">{section.section}</p>
                                {section.items.map((item) => (
                                    <SidebarItem key={item.name} item={item} />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="sidebar-footer">
                    <img src={certificates} alt="" />
                </div>
            </div>
        </>
    );
}

export default Sidebar;
