import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import Sidebar from "../components/Sidebar.jsx";
import Icon from "../media/icons.jsx"

import Apibg from "../media/servicesbackground.png";

function Apidoc() {
	const [activeSection, setActiveSection] = useState("");
	const location = useLocation();
	const isHome = location.pathname === "/";

	// Create refs for each section
	const aboutRef = useRef(null);
	const missionRef = useRef(null);
	const featuresRef = useRef(null);
	const benefitsRef = useRef(null);
	const gettingStartedRef = useRef(null);
	const supportRef = useRef(null);
	const conclusionRef = useRef(null);

	const sections = [
		{ id: "about-Axipays", title: "About Axipays", ref: aboutRef },
		{ id: "mission-vision", title: "Mission and Vision", ref: missionRef },
		{ id: "platform-features", title: "Platform Features", ref: featuresRef },
		{ id: "benefits", title: "Benefits of Choosing Axipays", ref: benefitsRef },
		{ id: "getting-started", title: "Getting Started with Axipays API", ref: gettingStartedRef },
		{ id: "support", title: "Technical Support and Customer Assistance", ref: supportRef },
		{ id: "conclusion", title: "Conclusion", ref: conclusionRef },
	];

	// Handle IntersectionObserver
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setActiveSection(entry.target.id);
					}
				});
			},
			{ threshold: 0.01 }
		);

		sections.forEach(({ ref }) => {
			if (ref.current) {
				observer.observe(ref.current);
			}
		});

		if (aboutRef.current) {
			setActiveSection("about-Axipays");
		}

		return () => {
			sections.forEach(({ ref }) => {
				if (ref.current) {
					observer.unobserve(ref.current);
				}
			});
		};
	}, []);

	// Handle manual clicks
	const handleLinkClick = (id, ref) => {
		setActiveSection(id); // Manually update active section
		ref.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			<Sidebar />
			<div className="apidoc">
				<div className="breadcrumb">
					<Link to="/" className="breadcrumb-link">
						Get started
					</Link>
					<span className="breadcrumb-separator"><Icon name='arrow_full_right' height={16} width={16} color="#003366" ></Icon></span>
					<span
						className={`breadcrumb-current ${isHome ? "highlight" : ""}`}
					>
						Introduction
					</span>
				</div>
				<div className="apidoc-header-infograph">
					<img src={Apibg} alt="Header Infograph" />
				</div>
				<div className="apidoc-header-headline">
					<h1>Introduction</h1>
				</div>
				<div className="apidoc-body">
					<div className="apidoc-body-mainContent">
						<p className="apidoc-note">
							Axipays provides a foundation that empowers merchants to gather
							and distribute payments globally, seamlessly utilizing the
							preferred payment channels at any given location and time.
						</p>

						<div className="bodyContent">
							<h2 ref={aboutRef} id="about-Axipays" className="apiDoc-heading">
								About Axipays
							</h2>
							<p>
								Welcome to Axipays, a comprehensive payment gateway solution
								that is redefining digital transactions for businesses
								worldwide. Axipays is crafted to be more than just a
								transactional service—it’s a robust, reliable, and scalable
								platform that enables businesses to handle financial
								transactions with ease and security. At Axipays, we understand
								the importance of a seamless, secure payment experience in
								today’s competitive digital landscape, and our mission is to
								provide a platform that meets these expectations for businesses
								of every size and sector.
								<br></br>
								<br></br>
								Currently, Axipays specializes in providing Server-to-Server
								(S2S) Payment Processing, an approach that allows for direct,
								secure communication between merchants’ servers and our payment
								infrastructure. This initial focus on S2S transactions empowers
								businesses by providing a backend solution that doesn’t require
								extensive user interface work, allowing our merchants to retain
								full control over the user experience, transaction logic, and
								design.
							</p>

							<h2
								ref={missionRef}
								id="mission-vision"
								className="apiDoc-heading"
							>
								Mission and Vision
							</h2>
							<p>
								At the heart of Axipays lies a clear mission and vision that
								guides everything we do. Our commitment is to create and
								maintain a seamless, secure payment gateway that simplifies the
								complexities of digital payments for businesses around the
								globe.
								<br></br>
								<br></br>
								<b>Mission:</b> Axipays is dedicated to building a reliable and
								accessible payment ecosystem for businesses, ensuring they can
								operate confidently and securely. Our mission is to provide a
								suite of robust, developer-friendly payment tools that adapt to
								the growing needs of digital commerce, helping businesses unlock
								new opportunities through innovative payment solutions.
								<br></br>
								<br></br>
								<b>Vision:</b> Axipays envisions a world where every business,
								regardless of size, can harness the power of digital payments
								with ease and security. Our goal is to become a global leader in
								payment processing, offering solutions that integrate
								effortlessly into any business model and scale with each
								company’s growth.
							</p>

							<h2
								ref={featuresRef}
								id="platform-features"
								className="apiDoc-heading"
							>
								Platform Features
							</h2>
							<p>
								Axipays is not just a payment gateway; it is a comprehensive
								solution crafted to address the demands of today’s digital
								economy. Here’s a breakdown of some of the core attributes that
								set Axipays apart:
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									a. Dedicated to S2S Payment Processing
								</h3>
								Axipays’ Server-to-Server (S2S) model is ideal for businesses
								that want to handle payments directly from their backend,
								without reliance on frontend interfaces. This approach provides
								greater control over each transaction, ensures efficient data
								handling, and allows businesses to implement a fully customized
								checkout experience that aligns with their brand and user
								experience requirements.
								<br></br>
								<br></br>
								<h3 className="secondHeading">b. Seamless Scalability</h3>
								Our infrastructure is designed to support businesses of all
								sizes. Whether you’re processing hundreds or millions of
								transactions, Axipays scales effortlessly to meet growing
								demand. Our platform grows with your business, adapting to
								traffic and transaction spikes with minimal latency and maximum
								reliability.
								<br></br>
								<br></br>
								<h3 className="secondHeading">c. Security-Centric Design</h3>
								In today’s digital landscape, security is paramount. Axipays
								integrates advanced encryption, secure authentication protocols,
								and real-time monitoring systems to safeguard each transaction.
								Our compliance with global payment standards ensures that
								sensitive data remains protected, helping merchants to build
								trust with their customers.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									d. Simple, Intuitive Integration
								</h3>
								We understand that integrating a new payment gateway can be a
								daunting task. That’s why the Axipays API is designed to be
								developer-friendly, with well-documented endpoints and intuitive
								workflows that make integration as straightforward as possible.
								Whether you’re working with a small team or a large-scale
								enterprise development team, our API provides the flexibility
								you need.
								<br></br>
								<br></br>
								<h3 className="secondHeading">e. 24/7 Support and Resources</h3>
								Axipays is more than a service; it’s a partnership. Our support
								team and extensive resource library are available to assist you
								at every step of your journey, from integration to optimization.
								Our dedicated support staff is trained to resolve issues
								swiftly, ensuring your transactions keep flowing smoothly.
							</p>

							<h2 ref={benefitsRef} id="benefits" className="apiDoc-heading">
								Key Benefits of Axipays
							</h2>
							<p>
								Choosing Axipays as your payment partner brings a host of
								benefits designed to enhance your operations, optimize
								transaction efficiency, and elevate your brand.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									a. Enhanced Control and Flexibility
								</h3>
								With our S2S model, businesses have complete control over the
								payment process. You can design the user flow to align precisely
								with your brand, tailor transaction handling to meet specific
								requirements, and manage data directly within your own system
								for improved accuracy and reporting.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									b. Unmatched Reliability and Speed
								</h3>
								Axipays leverages cutting-edge technology and robust
								infrastructure to deliver lightning-fast transaction processing
								and high availability. Our commitment to reliability ensures
								that your transactions are processed efficiently, minimizing
								downtime and ensuring your customers experience smooth,
								uninterrupted service.
								<br></br>
								<br></br>
								<h3 className="secondHeading">c. Stronger Security Measures</h3>
								Axipays understands that every transaction needs to be secure.
								From end-to-end encryption to PCI DSS compliance, we have
								implemented a host of security measures that not only protect
								sensitive data but also provide peace of mind to both you and
								your customers.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									d. Improved Customer Experience
								</h3>
								Speed and reliability are at the core of Axipays, helping to
								create a frictionless customer experience that encourages repeat
								business and builds trust. Your customers will benefit from
								faster transaction processing and fewer delays, making each
								interaction with your business more enjoyable.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									e. Extensive Documentation and Developer Resources
								</h3>
								At Axipays, we are committed to empowering your development team
								with the resources they need. Our comprehensive API
								documentation, SDKs, and developer guides provide in-depth
								explanations, examples, and troubleshooting tips, enabling you
								to integrate and manage payments with confidence.
							</p>

							<h2
								ref={gettingStartedRef}
								id="getting-started"
								className="apiDoc-heading"
							>
								Getting Started with Axipays API
							</h2>
							<p>
								Axipays provides a streamlined API that allows for seamless
								integration into any system, from e-commerce platforms to custom
								business applications. This documentation is your roadmap to
								setting up and using the Axipays API, starting with essential
								concepts and progressing to advanced use cases.
								<br></br>
								<br></br>
								<h3 className="secondHeading">a. API Overview</h3>
								The API Overview section introduces the fundamental principles
								of the Axipays API, outlining the key endpoints, authentication
								methods, and usage limits. This is the ideal starting point for
								understanding how to interact with our API effectively.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									b. Authentication and Authorization
								</h3>
								Our authentication protocols are designed to ensure secure
								access for all requests to the Axipays system. This section
								covers everything you need to know about token-based
								authentication, managing API keys, and handling permissions to
								keep your transactions secure.
								<br></br>
								<br></br>
								<h3 className="secondHeading">c. Managing Transactions</h3>
								Learn how to create, authorize, and complete transactions using
								the Axipays API. This section provides step-by-step instructions
								on managing transactions from start to finish, ensuring you can
								handle payments smoothly and efficiently.
								<br></br>
								<br></br>
								<h3 className="secondHeading">
									d. Error Handling and Troubleshooting
								</h3>
								Even the most carefully crafted systems encounter issues from
								time to time. Our Error Handling and Troubleshooting section is
								designed to help you quickly identify and resolve common issues,
								ensuring minimal disruption to your operations.
							</p>

							<h2 ref={supportRef} id="support" className="apiDoc-heading">
								Technical Support and Customer Assistance
							</h2>
							<p>
								Axipays takes pride in providing exceptional customer support.
								Our dedicated support team is available 24/7 to assist with any
								technical questions or issues you may encounter. From
								integration support to resolving technical issues, our team is
								ready to ensure a smooth experience. Additionally, Axipays
								provides a detailed knowledge base and community forums, where
								you can find answers to frequently asked questions, connect with
								other users, and share best practices.
							</p>

							<h2
								ref={conclusionRef}
								id="conclusion"
								className="apiDoc-heading"
							>
								Conclusion
							</h2>
							<p>
								Axipays is more than just a payment gateway; it’s a
								comprehensive partner in your business growth. By focusing on
								the essentials of server-to-server payment processing, we enable
								businesses to take full control of their payment experience,
								backed by unmatched reliability, security, and scalability. Our
								goal is to empower you with the tools, support, and
								infrastructure necessary to elevate your payment processes and
								drive customer satisfaction. Axipays is here to grow with you,
								and we look forward to being a trusted part of your journey in
								digital commerce.
							</p>
						</div>
					</div>

					<div className="apidoc-body-tableContent">
						<div className="bodyOverview-table">
							<h4>Table of Content</h4>
							<div className="bodyOverview-tableMenu">
								<ul className="bodyOverview-table-Submenu">
									<li>Getting started</li>
									<div className="bodyOverview-table-Submenu-items">
										{sections.map(({ id, title, ref }) => (
											<li
												key={id}
												className={activeSection === id ? "active" : ""}
											>
												<a
													href={`#${id}`}
													onClick={(e) => {
														e.preventDefault(); 
														handleLinkClick(id, ref);
													}}
												>
													{title}
												</a>
											</li>
										))}
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="apidoc-footer">
					<div>
						<Icon name="back_arrow" width={20} height={20}></Icon>
						<p>Previous</p>
					</div>
					<div>
						<p>Next</p>
						<Icon name="forward_arrow"></Icon></div>
				</div> */}
			</div>
		</>
	);
}

export default Apidoc;
