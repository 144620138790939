import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/Sidebar';
import ShortHeader from '../components/ShortHeader';
import Code from '../components/Code';

function S2S() {
    const [activeSection, setActiveSection] = useState('');
    const jsonRequestBody =
    `{
    "transactionId": "YOUR_REFERENCE_ID",
    "paymentDetails": {
            "amount": "100.00",
            "currencyCode": "USD",
            "method": "Card",
            "cardDetails": {
                "cardNumber": "4111111111111111",
                "expiryMonth": "03",
                "expiryYear": "2032",
                "cardCVC": "000"
            }
    },
    "billingDetails": {
            "firstName": "John",
            "lastName": "Doe",
            "email": "johndoe@gmail.com",
            "phone": "1111111111",
            "address": {
                "addressLine1": "123 Park Avenue",
                "addressLine2": "Suite 4",
                "city": "San Francisco",
                "state": "CA",
                "postalCode": "94111",
                "countryCode": "US"
            }
    },
    "callbackURL": "https://your-website.com/callback"
}`;

    const jsonResponseBody =
        `{
    "status": "success",
    "message": "Redirect URL generated",
    "data": {
        "redirect_url": "https://api.axipays.com/api/v1/redirecturl/$token"
    }
}`;

    const jsonResponeCallback =
    `{
    "code": "200",  
    "status": "Success",
    "message": "AUTHORIZED",
    "transactionId": "YOUR_REFERENCE_ID",
}`

    const jsonErrorresponse =
    `{
    "status": "error",
    "error": "error",
    "message": "Invalid request data",
}`

    const Webhookresponse =
    `{
    "amount": "100.00",
    "currency": "USD",
    "status": "Success",
    "payment_mode": "Card",
    "message": "AUTHORIZED",
    "transactionId": "YOUR_REFERENCE_ID",
}`

    // Create refs for each section
    const keyfeaturesRef = useRef(null);
    const transactionstepsRef = useRef(null);
    const inittransactionRef = useRef(null);
    const requestheaderRef = useRef(null);
    const requestbodyRef = useRef(null);
    const responsehandlingRef = useRef(null);
    const customerredirectionRef = useRef(null);
    const callbackmechRef = useRef(null);
    const realtimewebhookRef = useRef(null);
    const errorhandlingRef = useRef(null);
    const appendixRef = useRef(null);

    useEffect(() => {
        // Sections array to map refs with IDs
        const sections = [
            { id: 'keyFeatures', ref: keyfeaturesRef },
            { id: 'integrationSteps', ref: transactionstepsRef },
            { id: 'inittransaction', ref: inittransactionRef },
            { id: 'requestHeader', ref: requestheaderRef },
            { id: 'requestBody', ref: requestbodyRef },
            { id: 'responseHandling', ref: responsehandlingRef },
            { id: 'customerRedirection', ref: customerredirectionRef },
            { id: 'callbackMech', ref: callbackmechRef },
            { id: 'realTimeWebhook', ref: realtimewebhookRef },
            { id: 'errorHandling', ref: errorhandlingRef },
            { id: 'apendix', ref: appendixRef },
        ];

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.6 } // Adjust to detect when 60% of section is in view
        );

        // Observe each section
        sections.forEach(({ ref }) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up observer on component unmount
        return () => {
            sections.forEach(({ ref }) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    return (
        <>
            <Sidebar />
            <div className='apidoc'>
                <ShortHeader heading="Payment Integration" mainHeading="Axipays Server-to-Server (S2S) Integration" />

                <div className='apidoc-body'>
                    <div className='apidoc-body-mainContent'>
                        <div className="bodyContent">
                            <p className="apidoc-note">
                                Axipays Server-to-Server (S2S) integration provides a streamlined payment process by enabling direct
                                communication between your server and ours. This ensures secure, efficient handling of payment data.
                                Following PCI DSS standards, Axipays supports you in delivering a seamless integration experience.
                            </p>

                            <div >
                                <h3 ref={keyfeaturesRef} id="keyFeatures" >Key Features</h3>
                                <ul className='keyFeatures'>
                                <li className='keyfeature-li'><div className="lihead">1. Greater Transaction Control:</div > Offers full control over each transaction, allowing for tailored handling and customization.</li>
                                <li className='keyfeature-li'><div className="lihead">2. Efficient Data Handling: </div >Ensures streamlined and secure handling of payment data for optimal performance.</li>
                                <li className='keyfeature-li'><div className="lihead">3. Enhanced Security:</div > Complies with industry standards to securely process sensitive payment information.</li>
                                </ul>
                            </div>
                            <div >
                                <h3 ref={keyfeaturesRef} id="keyFeatures" >Base Url</h3>
                                <div className='codeContainer'>
                                    <Code
                                        codeBody={`https://api.axipays.com`}
                                        className="javascript"
                                        customRender={false}
                                    />
                                </div>

                            </div>
                            <h3 className='bodyContent-heading' ref={transactionstepsRef} id="integrationSteps">Integration Steps</h3>
                            <div className='page-content-pointers'>
                                <div className='Steps' ref={inittransactionRef} id="inittransaction">
                                    <h3>1. Transaction Initiation (POST Request)</h3>
                                    <p className='para'>The merchant initiates a transaction by sending a POST request to the following endpoint:</p>
                                    <div className='codeContainer'>
                                        <Code
                                            codeHeader="EndPoint"
                                            codeBody={`/api/v1/initiate_transaction?method=s2s`}
                                            className="javascript"
                                            customRender={true}
                                        />
                                    </div>

                                </div>
                                <div className='Steps'>
                                    <h3 ref={requestheaderRef} id="requestHeader" >2. Request Headers</h3>
                                    <p className='para'>Axipays requires the following headers to authenticate and validate each request:</p>
                                    <div className='scrollable-table-container'>
                                        <table className="parameter-table">
                                            <thead>
                                                <tr>
                                                    <th>Header</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Authorization</td>
                                                    <td>Unique key provided by Axipays to identify the merchant, available in the Profile section of the portal.</td>
                                                </tr>
                                                <tr>
                                                    <td>api-secret</td>
                                                    <td>Secret key to validate the merchant’s identity, also available in the Profile section.</td>
                                                </tr>
                                                <tr>
                                                    <td>mid</td>
                                                    <td>Merchant Identification provided by Axipays; use only if explicitly assigned.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="apidoc-note notePadding">
                                        Ensure that the request headers are included with every API request. These headers are essential for authenticating and validating the merchant.
                                    </p>
                                    <div>
                                        <h3 id='requestBody' ref={requestbodyRef}>3. Request Body</h3>
                                        <p className='para'> The request body should be a valid JSON object containing the transaction details:

                                            {/* <ul>
                                                <li><i>
                                                    Success: If the details are correct, a success message and a redirect URL are returned in the response.
                                                </i>
                                                </li>
                                                <li>
                                                    <i>
                                                        Failure: If the details are invalid, an error message is returned.
                                                    </i>
                                                </li>
                                            </ul> */}
                                        </p>
                                        <div className='codeContainer'>
                                            <Code
                                                codeHeader="Sample Request Body:"
                                                codeBody={jsonRequestBody}
                                                className="json"
                                                customRender={false}
                                            />
                                        </div>
                                        <div className='scrollable-table-container'>
                                            <table className="parameter-table">
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Type</th>
                                                        <th>Required</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>transactionId</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Unique identifier for the transaction.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>paymentDetails</td>
                                                        <td>object</td>
                                                        <td>Yes</td>
                                                        <td>Contains transaction amount, currency, and payment method.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp; amount</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>The transaction amount.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;currencyCode</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>3-letter ISO currency code (e.g., "USD").</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;method</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Payment method (e.g., "Card", "UPI").</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;cardDetails</td>
                                                        <td>object</td>
                                                        <td>No</td>
                                                        <td>Credit card details, required for card payments.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;cardNumber</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Card number of the payer.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;expiryMonth</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Expiration month of the card (MM format).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;expiryYear</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Expiration year of the card (YYYY format).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;cardCVC</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>3-digit card security code/ card verification number.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;billingDetails</td>
                                                        <td>object</td>
                                                        <td>Yes</td>
                                                        <td>Details about the payer’s billing information.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;firstName</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Customer's first name.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;lastName</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Customer's last name.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;email</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Customer's email address.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;phone</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>Customer's phone number (min 10, max 15 characters).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;address</td>
                                                        <td>object</td>
                                                        <td>No</td>
                                                        <td>Customer's full address.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;addressLine1</td>
                                                        <td>string</td>
                                                        <td>No</td>
                                                        <td>The primary address line for key address details such as street name, house number, or apartment name.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;addressLine2</td>
                                                        <td>string</td>
                                                        <td>No</td>
                                                        <td>The secondary address line for additional address details like suite, floor, or building information.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;city</td>
                                                        <td>string</td>
                                                        <td>No</td>
                                                        <td>Customer's city.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;state</td>
                                                        <td>string</td>
                                                        <td>No</td>
                                                        <td>The state, province, or region where the customer is located.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;postalCode</td>
                                                        <td>string</td>
                                                        <td>No</td>
                                                        <td>The postal code or ZIP code of the customer's address.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;countryCode</td>
                                                        <td>string</td>
                                                        <td>No</td>
                                                        <td>A two-letter ISO 3166-1 alpha-2 country code representing the country of the customer's address.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>callbackURL</td>
                                                        <td>string</td>
                                                        <td>Yes</td>
                                                        <td>URL for receiving transaction status updates. Must be a valid URL.</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div>
                                        <h3 id='responseHandling' ref={responsehandlingRef}>4. Response Handling</h3>
                                        <p className='para'> Upon successful validation, Axipays returns a redirect URL in the response. The merchant must
                                            redirect the customer to this URL for payment authorization.
                                        </p>
                                        <div className='codeContainer'>
                                            <Code
                                                codeHeader="Sample Response:"
                                                codeBody={jsonResponseBody}
                                                className="json"
                                                customRender={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 id='customerRedirection' ref={customerredirectionRef}>Customer Redirection</h3>
                                <p> Redirect the customer to the redirectUrl provided in the response. The customer will complete the
                                    payment authorization on this page.
                                </p>
                            </div>
                            <div>
                                <h3 id='callbackMech' ref={callbackmechRef}>Callback Mechanism</h3>
                                <p> Once the payment is authorized, Axipays will send a callback to the callbackURL provided in the
                                    request. This callback will include the transaction status.
                                </p>
                                <div className='codeContainer'>
                                    <Code
                                        codeHeader="Sample Callback Payload:"
                                        codeBody={jsonResponeCallback}
                                        className="json"
                                        customRender={false}
                                    />
                                </div>
                                {/* <p className="apidoc-note notePadding">
                                    Axipays provides a foundation that empowers merchants to gather
                                    and distribute payments globally, seamlessly utilizing the
                                    preferred payment channels at any given location and time.
                                </p> */}
                            </div>

                            <div>
                                <h3 id='realTimeWebhook' ref={realtimewebhookRef}>Real-Time Webhooks</h3>
                                <p> To receive ongoing transaction updates, integrate Axipays’ webhook services. Configure webhook
                                    endpoints in your Axipays merchant portal.
                                </p>

                                <div className='codeContainer'>
                                    <Code
                                        codeHeader="WebHook"
                                        codeBody={Webhookresponse}
                                        className="json"
                                        customRender={false}
                                    />
                                </div>
                            </div>

                            <div>
                                <h3 id='errorHandling' ref={errorhandlingRef}>Error Handling</h3>
                                <p> Axipays returns descriptive error messages for failed requests. Ensure you handle the following common scenarios:
                                    <ul className='keyFeatures'>
                                        <li><strong>400</strong> Bad Request: Invalid request parameters.</li>
                                        <li><strong>401</strong> Unauthorized: Missing or invalid Authorization Key or API Secret.</li>
                                        <li><strong>500</strong> Internal Server Error: Server-side issue, retry after some time.</li>
                                    </ul>
                                </p>
                                <div className='codeContainer'>
                                    <Code
                                        codeHeader="Sample Error Response:"
                                        codeBody={jsonErrorresponse}
                                        className="json"
                                        customRender={false}
                                    />
                                </div>

                                <p className="apidoc-note notePadding">
                                    The 'error' field indicates the issue encountered when sending the details, while the 'message' field provides a detailed description of the error.
                                </p>
                            </div>

                        </div>

                    </div>
                    <div className="apidoc-body-tableContent">
                        <div className="bodyOverview-table">
                            <h4>Table of Content</h4>
                            <div className="bodyOverview-tableMenu">
                                <ul className="bodyOverview-table-Submenu">
                                    <li>S2S Integration</li>
                                    <ul className="bodyOverview-table-Submenu-items">
                                        <li className={activeSection === 'keyFeatures' ? 'active' : ''} ><a href="#keyFeatures">Key Features</a></li>
                                        <li className={activeSection === 'integrationSteps' ? 'active' : ''}><a href="#integrationSteps">Integration Steps</a></li>
                                        <div className='bodyOverview-table-Submenu-items-submenu'>
                                            <li className={activeSection === 'inittransaction' ? 'active' : ''}><a href="#inittransaction">1. Transaction Initiation (POST Request)</a></li>
                                            <li className={activeSection === 'requestHeader' ? 'active' : ''}><a href="#requestHeader">2. Request Headers</a></li>
                                            <li className={activeSection === 'requestBody' ? 'active' : ''}><a href="#requestBody">3. Request Body</a></li>
                                            <li className={activeSection === 'responseHandling' ? 'active' : ''}><a href="#responseHandling">4. Response Handling</a></li>
                                        </div>

                                        <li className={activeSection === 'customerRedirection' ? 'active' : ''}><a href="#customerRedirection">Customer Redirection</a></li>
                                        <li className={activeSection === 'callbackMech' ? 'active' : ''}><a href="#callbackMech">Callback Mechanism</a></li>
                                        <li className={activeSection === 'realTimeWebhook' ? 'active' : ''}><a href="#realTimeWebhook">Real-Time Webhooks</a></li>
                                        <li className={activeSection === 'errorHandling' ? 'active' : ''}><a href="#errorHandling">Error Handling</a></li>
                                        <li className={activeSection === 'apendix' ? 'active' : ''}><a href="#appendix">Appendix: Parameters Reference</a></li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default S2S