import React from "react";
import { useLocation, Link } from "react-router-dom";
import Icon from "../media/icons";
import axipayspg from "../media/Axipays_Horizontal_Logo_RGB_Full_Color.png";

function ShortHeader({ heading, mainHeading }) {
	const location = useLocation();
	const pathSegments = location.pathname.split("/").filter(Boolean);

	// Breadcrumb mappings for segment names
	const breadcrumbMapping = {
		s2sIntegration: "S2S",
		iframeIntegration: "Iframe",
		paymentIntegration: "Integration Methods",
		errorHandling: "Error Handling",
		dashboardOverview:"Overview",
	};

	// Check if the path should replace paymentIntegration with "Overview"
	const replacePaymentIntegration = ["dashboard", "extractCred"].some((path) =>
		pathSegments.includes(path)
	);

	// Generate breadcrumbs dynamically based on path segments
	const breadcrumbs = pathSegments.map((segment, index) => {
		const isLast = index === pathSegments.length - 1;
		const isUnderIntegration = ["s2sIntegration", "iframeIntegration"].includes(segment);

		let displayText;
		if (replacePaymentIntegration && segment === "paymentIntegration") {
			displayText = "Overview";
		} else if (isUnderIntegration && !isLast) {
			displayText = "Integration Method";
		} else {
			displayText = breadcrumbMapping[segment] || segment;
		}

		// Return breadcrumb links and separators for each segment
		return (
			<React.Fragment key={index}>
				{!isLast ? (
					<Link
						to={`/${pathSegments.slice(0, index + 1).join("/")}`}
						className="breadcrumb-link"
					>
						{displayText}
					</Link>
				) : (
					<span className="breadcrumb-current">{displayText}</span>
				)}
				{!isLast && (
					<span className="breadcrumb-separator">
						<Icon name="arrow_full_right" height={24} width={24} />
					</span>
				)}
			</React.Fragment>
		);
	});

	// Add "Error Handling" breadcrumb if "errorCodes" is in the path
	if (pathSegments.includes("errorCodes")) {
		breadcrumbs.unshift(
			<React.Fragment key="error-handling">
				<Link to="/errorHandling" className="breadcrumb-link">
					Error Handling
				</Link>
				<span className="breadcrumb-separator">
					<Icon name="arrow_full_right" height={24} width={24} color="#888" />
				</span>
			</React.Fragment>
		);
	}

	// Add "Integration Method" breadcrumb for specific integrations
	if (["s2sIntegration", "iframeIntegration"].some((path) => pathSegments.includes(path))) {
		breadcrumbs.unshift(
			<React.Fragment key="integration-method">
				<Link to="/paymentIntegration" className="breadcrumb-link">
					Integration Method
				</Link>
				<span className="breadcrumb-separator">
					<Icon name="arrow_full_right" height={24} width={24} color="#003366" />
				</span>
			</React.Fragment>
		);
	}

	// Add "Overview" breadcrumb for Dashboard Tour
	if (replacePaymentIntegration) {
		breadcrumbs.unshift(
			<React.Fragment key="dashboard-tour">
				<Link to="/dashboard" className="breadcrumb-link">
					Overview
				</Link>
				<span className="breadcrumb-separator">
					<Icon name="arrow_full_right" height={24} width={24} color="#888" />
				</span>
			</React.Fragment>
		);
	}

	// Render the ShortHeader component
	return (
		<div className="ShortHeader">
			<div className="axipays-pg">
				<img src={axipayspg} alt="" className=""/>
			</div>
			{/* Render breadcrumbs */}
			<div className="breadcrumb">
				<span className="breadcrumb-text">
					{replacePaymentIntegration ? "Dashboard Tour" : "Payment Integration"}
				</span>
				<span className="breadcrumb-separator">
					<Icon name="arrow_full_right" height={24} width={24} color="#888" />
				</span>
				{breadcrumbs}
			</div>

			{/* Render the heading and mainHeading */}
			<div className="headerHeadline">
				{/* <p>{heading}</p> */}
				<h1>{mainHeading}</h1>
			</div>
		</div>
	);
}

export default ShortHeader;