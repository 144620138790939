import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import ShortHeader from "../components/ShortHeader";
import Icon from "../media/icons.jsx";

function PaymentIntegration() {
	const [activeSection, setActiveSection] = useState("");

	// Create refs for each section
	const s2sIntegrationRef = useRef(null);
	const iframeIntegrationRef = useRef(null);

	useEffect(() => {
		// Sections array to map refs with IDs
		const sections = [
			{ id: "s2s-integration", ref: s2sIntegrationRef },
			{ id: "iframe-integration", ref: iframeIntegrationRef },
		];

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setActiveSection(entry.target.id);
					}
				});
			},
			{ threshold: 0.6 } // Adjust to detect when 60% of section is in view
		);

		// Observe each section
		sections.forEach(({ ref }) => {
			if (ref.current) {
				observer.observe(ref.current);
			}
		});

		// Clean up observer on component unmount
		return () => {
			sections.forEach(({ ref }) => {
				if (ref.current) {
					observer.unobserve(ref.current);
				}
			});
		};
	}, []);

	return (
		<>
			<Sidebar />
			<div className="apidoc">
				<ShortHeader heading="Payment Integration" mainHeading="Integration Methods" />

				<div className="apidoc-body">
					<div className="apidoc-body-mainContent">
						<div className="bodyContent">
							<p className="apidoc-note">
								Axipays provides flexible and secure payment integration options
								to suit diverse merchant needs. Our payment gateway supports
								various integration methods, ensuring seamless transactions
								while meeting industry standards for security and user
								experience. 
							</p>
							{/* <ul className="integration-methods-list">
								<li>Server-to-Server (S2S)</li>
								<li>Integration Iframe Integration</li>
							</ul> */}
							<h2
								ref={s2sIntegrationRef}
								id="s2s-integration"
								className="apiDoc-heading"
							>
								1. Server-to-Server (S2S) Payment Integration
							</h2>
							<p className="bodyContent-para">
								S2S payment integration is ideal for merchants requiring full
								control over their payment process. In this model, the payment
								details are securely transmitted directly from the merchant’s
								server to Axipays, enabling advanced customizations and
								streamlined workflows.
									</p>
					
								<div className='bodycontent-subdiv'>Axipays supports two S2S options:</div>
								<div className="bodycontent-li">
									<div>
									<div className='bodycontent-subdiv'>3D Secure (3DS):</div>
										<div>
											This option adds an additional layer of authentication
											during transactions, ensuring higher security and reduced
											fraud. Customers are redirected to their issuing bank's
											authentication page (e.g., OTP or password verification)
											before completing the payment. 3DS is highly recommended
											for high-risk transactions or merchants processing
											international payments.
										</div>
									</div>
									<div>
									<div className='bodycontent-subdiv'>2D Secure (2DS):</div>
										<div>
											The 2DS method skips the additional customer
											authentication step, providing a faster checkout
											experience. This option is suitable for low-risk,
											high-speed transactions where security measures are
											already in place on the merchant’s side.
										</div>
									</div>
								</div>
								<div className='bodycontent-subdiv'>Benefits of S2S Integration:</div>
								<div className="bodycontent-li benefits-li">
									<ul>
									<li>Direct control over transaction flow.</li>
									<li>Enhanced security for sensitive data.</li>
									<li>Compliance with PCI DSS standards.</li>
									<li>Support for advanced routing and payment logic.</li>
									</ul>
								</div>
						

							<h2
								ref={iframeIntegrationRef}
								id="iframe-integration"
								className="apiDoc-heading"
							>
								2. Iframe Payment Integration
							</h2>
							<p className="bodyContent-para">
								The iframe integration allows merchants to embed the Axipays
								payment interface directly into their website or application.
								This method simplifies the payment process while maintaining a
								seamless customer experience. This method offers two
								customization options, catering to different levels of merchant
								involvement in collecting user payment details.
								</p>
								<div className='bodycontent-subdiv'>Customization Options:</div>
								<ul>
									<li>
										<h3>1. Axipays-Hosted Form Display</h3>
										<p>
											In this customization, Axipays provides a fully
											responsive payment form embedded into the merchant’s
											website via an iframe. Customers fill in their payment
											details (e.g., card number, expiry date, CVV) directly
											in the Axipays-hosted form, ensuring data is securely
											captured and processed.
										</p>
										<div className="bodycontent-li">
											<div className='bodycontent-subdiv'>Key Features:</div>
											<div>
												Axipays handles all data collection securely within the
												iframe.
											</div>
											<div>
												Merchants only need to implement the iframe URL on
												their platform.
											</div>
											<div>
												Fully PCI DSS-compliant with no merchant-side
												liability for sensitive data.
											</div>
										</div>
										<div className="bodycontent-li">
											<div className='bodycontent-subdiv'>Ideal Use Case:</div>
											<div>
												Merchants who prefer to offload the responsibility of
												capturing and validating payment details.
											</div>
											<div>
												Businesses looking for quick and easy payment
												integration with minimal development effort.
											</div>
										</div>
									</li>

									<li>
										<h3>2. Merchant-Provided User Details</h3>
										<p>
											For merchants who have already collected user payment
											details on their platform, this customization allows them
											to directly pass the collected data to Axipays through the
											iframe. The iframe is used for seamless transaction
											processing without displaying a payment form to the
											customer.
										</p>
									</li>
								</ul>
								<div className="bodycontent-li">
									<div className='bodycontent-subdiv'>Key Features:</div>
									<div>
										Merchants collect and validate user payment details.
									</div>
									<div>
										The iframe is used to securely transmit the data to Axipays.
									</div>
									<div>
										Provides faster checkout.
									</div>
								</div>
								<div className="bodycontent-li">
									<div className='bodycontent-subdiv'>Ideal Use Case:</div>
									<div>
										Merchants with existing customer interfaces for collecting
										payment details.
									</div>
									<div>
										Platforms requiring minimal user interaction at the payment
										stage.
									</div>
								</div>
						
						</div>
					</div>
					<div className="apidoc-body-tableContent">
						<div className="bodyOverview-table">
							<h4>Table of Content</h4>
							<ul className="bodyOverview-tableMenu">
							  <li>Integration Method</li>
								<ul className="bodyOverview-table-Submenu">
									<div className="bodyOverview-table-Submenu-items">
										<li className={
											activeSection === "s2s-integration" ? "active" : ""
										}>
											<a
												href="#s2s-integration"

											>
												Server-to-Server (S2S) Integration
											</a>
										</li>
										<li className={
											activeSection === "iframe-integration" ? "active" : ""
										}>
											<a
												href="#iframe-integration"

											>
												Iframe Integration
											</a>
										</li>
									</div>
								</ul>
							</ul>
						</div>
					</div>
				</div>
				{/* <div className="apidoc-footer">
					<div>
						<Icon name="back_arrow" width={20} height={20}></Icon>
						<p>Previous</p>
					</div>
					<div>
						<p>Next</p>
						<Icon name="forward_arrow"></Icon>
					</div>
				</div> */}
			</div>
		</>
	);
}

export default PaymentIntegration;