import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/Sidebar';
import ShortHeader from '../components/ShortHeader';
import Code from '../components/Code';
// import Icon from "../media/icons";

function IframeIntegration() {
    const [activeSection, setActiveSection] = useState('');
    const jsCode =
`<script>
      window.onload = () => {
        const iframe = document.getElementById("paymentIframe");

        const paymentData = {
            iframeToken: "YOUR_IFRAME_TOKEN",
            transactionId: "YOUR_REFERENCE_ID",
            callbackURL: "https://your-website.com/callback",
            mid: "YOUR_MID",
            paymentDetails: {
                amount: "100.00",
                currencyCode: "USD",
                method: "Card",
                cardDetails: {
                    cardNumber: "4111111111111111",
                    expiryMonth: "03",
                    expiryYear: "2030",
                    cardCVC: "737",
                },
            },
            billingDetails: {
                firstName: "Jon",
                lastName: "Doe",
                email: "johndoe@gmail.com",
                phone: "1111111111",
                address:{
                    addressLine1:"123 Payment Avenue",
                    addressLine2:"Suite 4",
                    city:"San Francisco",
                    state:"CA",
                    postalCode:"94111",
                    countryCode:"US"
                }
          },
        };

        iframe.contentWindow.postMessage(paymentData, "https://portal.axipays.com");
      };
</script>`;

    const jsCodeShowForm =
        `<script>
  window.onload = () => {
    const iframe = document.getElementById("paymentIframe");

    const paymentData = {
        iframeToken: "YOUR_IFRAME_TOKEN",
        transactionId: "YOUR_REFERENCE_ID",
        callbackURL: "https://your-website.com/callback",
        mid: "YOUR_MID",
        paymentDetails: {
            amount: "100.00",
            currencyCode: "USD",
            method: "Card",
            cardDetails: {
                cardNumber: "",
                expiryMonth: "",
                expiryYear: "",
                cardCVC: "",
            },
        },
        billingDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address:{
                addressLine1:"",
                addressLine2:"",
                city:"",
                state:"",
                postalCode:"",
                countryCode:""
            }
        },
    };

    iframe.contentWindow.postMessage(paymentData, "https://portal.axipays.com");
  };
</script>`;

    const iframResponeHandling =
        `window.addEventListener('message', function (event) {
            if (event.origin === 'https://centpays.com') {
                const response = event.data;
                console.log('Payment Response:', response);
                // Handle success or failure based on response
            }
});`;

    const jsonResponeCallback =
        `{
        "transactionId": "YOUR_MERCHANT_TXNID",
        "status": "approved",
        "amount": "100.00",
        "currencyCode": "USD",
        "message": "Payment successful"
}`

    const jsonErrorresponse =
        `{
        "status": "error",
        "errorCode": "401",
        "message": "Invalid Authorization Key."
}`

    // Create refs for each section
    const formdiplayedRef = useRef(null);
    const formdiplayedintigrationRef = useRef(null);
    const hostediframeRef = useRef(null);
    const sendingiframeRef = useRef(null);
    const userDetailsRef = useRef(null);
    const userDetailsintigrationRef = useRef(null);
    const userdetailiframeRef = useRef(null);
    const sendinguserdetailRef = useRef(null);
    const customerredirectionRef = useRef(null);
    const errorhandlingRef = useRef(null);

    useEffect(() => {
        // Sections array to map refs with IDs
        const sections = [
            { id: 'formdisplayed', ref: formdiplayedRef },
            { id: 'formdisplayedintigration', ref: formdiplayedintigrationRef },
            { id: 'hostediframe', ref: hostediframeRef },
            { id: 'sendingiframe', ref: sendingiframeRef },
            { id: 'userdetails', ref: userDetailsRef },
            { id: 'userdetailsintigration', ref: userDetailsintigrationRef },
            { id: 'userdetailiframe', ref: userdetailiframeRef },
            { id: 'sendinguserdetail', ref: sendinguserdetailRef },
            { id: 'customerRedirection', ref: customerredirectionRef },
            { id: 'errorHandling', ref: errorhandlingRef },
        ];

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.6 } // Adjust to detect when 60% of section is in view
        );

        // Observe each section
        sections.forEach(({ ref }) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up observer on component unmount
        return () => {
            sections.forEach(({ ref }) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    return (
        <>
            <Sidebar />
            <div className='apidoc'>

                <ShortHeader heading="Payment Integration" mainHeading="IFrame Integration for Payment Gateway" />

                <div className='apidoc-body'>
                    <div className='apidoc-body-mainContent'>
                        <div className="bodyContent">
                            <div className="bodyContent">
                                <p className='bodyContent-para'>
                                    Axipays IFrame Integration enables merchants to securely embed a payment form within their website.
                                    This method allows customers to complete transactions without being redirected to an external page,
                                    ensuring a seamless and branded user experience. Axipays IFrame Integration offers two flexible methods
                                    to suit diverse merchant needs. The first method, Axipays-Hosted Form Display, embeds a fully responsive
                                    payment form directly into the merchant's website. The second method, Merchant-Provided User Details, is
                                    designed for merchants who already collect payment information on their platform.
                                </p>

                                <p className="apidoc-note">
                                    Please ensure the <b>showForm parameter</b> is set to <b>true</b> for Axipays-Hosted
                                    Form Display to enable the embedded payment form. Conversely, for Merchant-Provided
                                    User Details, kindly set it to <b>false</b> to securely pass collected payment
                                    data for processing.
                                </p>

                                <div >
                                    <h3 ref={formdiplayedRef} id="formdisplayed">1. Axipays-Hosted Form Display</h3>
                                    <h3>Key Features</h3>
                                    <ul className='keyFeatures'>
                                        <li className='keyfeature-li'><div className="lihead">1. Embedded Payment Form:</div > Seamlessly integrates into your website.</li>
                                        <li className='keyfeature-li'><div className="lihead">2. Enhanced Security:</div > PCI DSS compliant, with sensitive data handled securely.</li>
                                        <li className='keyfeature-li'><div className="lihead">3. Customizable Experience:</div > Allows control over form visibility and styling.</li>
                                    </ul>
                                </div>

                                <h3 className='bodyContent-heading' ref={formdiplayedintigrationRef} id="formdisplayedintigration">Integration Steps</h3>


                                <div className='page-content-pointers'>
                                    <div className='Steps' >
                                        <h3 ref={hostediframeRef} id="hostediframe">1. IFrame Setup</h3>
                                        <p className='para'>To embed the IFrame, use the following HTML code:</p>
                                        <div className='codeContainer'>
                                            <Code
                                                codeHeader="Iframe setup"
                                                codeBody={`<iframe id="paymentIframe" src="https://portal.axipays.com/iframe?showForm=true"></iframe>`}
                                                className="language-html"
                                                customRender={false}
                                            />
                                        </div>

                                    </div>

                                    <div className='Steps'>
                                        <h3 ref={sendingiframeRef} id="sendingiframe" >2. Sending Data to IFrame</h3>
                                        <p className='para'>Once the IFrame is loaded, send payment details using the postMessage method.</p>
                                        <div className='codeContainer'>
                                            <Code
                                                codeHeader="JavaScript code:"
                                                codeBody={jsCodeShowForm}
                                                className="javascript"
                                                customRender={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                <h3 id='customerRedirection' ref={customerredirectionRef}>Notes</h3>
                                <ul className='keyFeatures'>
                                    <li>
                                        <strong>1. IFrame Token:</strong><br></br>
                                        &nbsp;&nbsp;&nbsp; Obtain your iframe_token from the Axipays back-office panel under the Settings tab.
                                    </li>
                                    <li>
                                        <strong>2. Transaction ID:</strong><br></br>
                                        &nbsp;&nbsp;&nbsp; Ensure transaction_id is unique for each transaction to avoid conflicts.
                                    </li>
                                    <li>
                                        <strong>3. Card Expiry:</strong><br></br>
                                        &nbsp;&nbsp;&nbsp; Use the correct format for the card expiry. For example, if the expiry is January 2028, enter 01/2028.
                                    </li>
                                    <li>
                                        <strong>4. MID (Merchant ID):</strong><br></br>
                                        &nbsp;&nbsp;&nbsp; Only use mid if Axipays has assigned multiple MIDs to your account. If not applicable, this parameter can be omitted.
                                    </li>
                                </ul>
                            </div> */}
                                {/* <div>
                                <h3 id='errorHandling' ref={errorhandlingRef}>Error Handling</h3>
                                <p>
                                    Axipays provides detailed error messages if the transaction fails or parameters are invalid. Ensure
                                    proper error handling by capturing the response in the IFrame and displaying relevant messages to the
                                    customer.
                                </p>
                            </div> */}

                            </div>
                            <div >
                                <h3 ref={userDetailsRef} id="userdetails" >2. Merchant-Provided User Details</h3>
                                <h3>Key Features</h3>
                                <ul className='keyFeatures'>
                                    <li className='keyfeature-li'><div className="lihead">1. Merchant-Controlled Data Collection:</div> Merchants have full control over collecting customer data on their own form.</li>
                                    <li className='keyfeature-li'><div className="lihead">2. Responsibility for Compliance:</div>Merchant must ensure PCI DSS compliance as sensitive data is handled directly.</li>
                                    <li className='keyfeature-li'><div className="lihead">3. Flexibility in Design:</div>Complete freedom to design and style the form to match the brand's requirements.</li>
                                </ul>
                            </div>

                            <h3 className='bodyContent-heading' ref={userDetailsintigrationRef} id="userdetailsintigration">Integration Steps</h3>
                            <div className='page-content-pointers'>
                                <div className='Steps' >
                                    <h3 ref={userdetailiframeRef} id="userdetailiframe" >1. IFrame Setup</h3>
                                    <p>To securely send customer data collected on your form, use the following URL :</p>
                                    <div className='codeContainer'>
                                        <Code
                                            codeHeader="Iframe setup"
                                            codeBody={`<iframe id="paymentIframe" src="https://portal.axipays.com/iframe?showForm=false"></iframe>`}
                                            className="language-html"
                                            customRender={false}
                                        />
                                    </div>

                                </div>

                                <div className='Steps'>
                                    <h3 ref={sendinguserdetailRef} id="sendinguserdetail" >2. Sending Data using Post Message</h3>
                                    <p>Payment details are securely transmitted to the IFrame using the postMessage method.</p>
                                    <div className='codeContainer'>
                                        <Code
                                            codeHeader="JavaScript code:"
                                            codeBody={jsCode}
                                            className="javascript"
                                            customRender={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='iFrame-notes'>
                                <h3 id='customerRedirection' ref={customerredirectionRef}>Notes</h3>
                                <ul className='keyFeatures-notes'>
                                    <li>
                                        <b>1. IFrame Token:</b><br></br>
                                        Obtain your iframe_token from Axipays.
                                    </li>
                                    <li>
                                        <b>2. Transaction ID:</b><br></br>
                                        Ensure transaction_id is unique for each transaction to avoid conflicts.
                                    </li>
                                    <li>
                                        <b>3. Card Expiry:</b><br></br>
                                        Ensure correct format for the card expiry and it should be a future date.
                                    </li>
                                    <li>
                                        <b>4. MID (Merchant ID):</b><br></br>
                                        Only use mid if Axipays has assigned you. If not applicable, this parameter can be omitted.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 id='errorHandling' ref={errorhandlingRef}>Error Handling</h3>
                                <p>
                                    Axipays provides detailed error messages if the transaction fails or parameters are invalid. Ensure
                                    proper error handling by capturing the response in the IFrame and displaying relevant messages to the
                                    customer.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="apidoc-body-tableContent">
                        <div className="bodyOverview-table">
                            <h4>Table of Content</h4>
                            <div className="bodyOverview-tableMenu">
                                <ul className="bodyOverview-table-Submenu">
                                    <li>Iframe Integration</li>
                                    <ul className="bodyOverview-table-Submenu-items">
                                        <li className={activeSection === 'formdisplayed' ? 'active' : ''} ><a href="#formdisplayed">Axipays-Hosted Form Display</a></li>
                                        <li className={activeSection === 'formdisplayedintigration' ? 'active' : ''}><a href="#formdisplayedintigration">Integration Steps</a></li>
                                        <div className='bodyOverview-table-Submenu-items-submenu'>
                                            <li className={activeSection === 'hostediframe' ? 'active' : ''}><a href="#hostediframe">1. IFrame Setup</a></li>
                                            <li className={activeSection === 'sendingiframe' ? 'active' : ''}><a href="#sendingiframe">2. Sending Data to IFrame</a></li>
                                        </div>

                                        <li className={activeSection === 'userdetails' ? 'active' : ''} ><a href="#userdetails">Merchant-Provided User Details</a></li>
                                        <li className={activeSection === 'userdetailsintigration' ? 'active' : ''}><a href="#userdetailsintigration">Integration Steps</a></li>
                                        <div className='bodyOverview-table-Submenu-items-submenu'>
                                            <li className={activeSection === 'userdetailiframe' ? 'active' : ''}><a href="#userdetailiframe">1. IFrame Setup</a></li>
                                            <li className={activeSection === 'sendinguserdetail' ? 'active' : ''}><a href="#sendinguserdetail">2. Sending Data to IFrame</a></li>
                                        </div>

                                        <li className={activeSection === 'customerRedirection' ? 'active' : ''}><a href="#customerRedirection">Notes</a></li>
                                        <li className={activeSection === 'errorHandling' ? 'active' : ''}><a href="#errorHandling">Error Handling</a></li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IframeIntegration;