import React from "react";

const icons = {
	arrow_left: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
			<path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
		</svg>
	),

	arrow_right: (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path d="m13.172 12l-4.95-4.95l1.414-1.413L16 12l-6.364 6.364l-1.414-1.415z" />
		</svg>
	),
	arrow_full_right: (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1639_16170)">
				<path d="M7.8065 10.1938L9.53317 8.46714C9.79317 8.20714 9.79317 7.78714 9.53317 7.52714L7.8065 5.80047C7.3865 5.38714 6.6665 5.68047 6.6665 6.2738V9.72047C6.6665 10.3205 7.3865 10.6138 7.8065 10.1938Z" fill="#626262" />
			</g>
			<defs>
				<clipPath id="clip0_1639_16170">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>

	),

	back_arrow: (
		<svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
			<path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
		</svg>
	),
	forward_arrow: (
		<svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
			<path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
		</svg>
	),
	light_mode: (
		<svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
			<path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
		</svg>
	),
	copy: (
		<svg viewBox="0 0 19 22" xmlns="http://www.w3.org/2000/svg">
			<path d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM17 4H6C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V6C19 
		4.9 18.1 4 17 4ZM17 20H6V6H17V20Z"/>
		</svg>
	),
	circle_arrow_right: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m480-320 160-160-160-160-56 56 64 64H320v80h168l-64 64 56 56Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
	),
	circle_arrow_left: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
		</svg>
	)
};

const Icon = ({ name, width = 24, height = 24, color = '#000', className = '', onClick = () => { } }) => {
	const SelectedIcon = icons[name];
	return (
		<span className={className} style={{ display: 'inline-block', width, height }} onClick={onClick}>
			{SelectedIcon ? React.cloneElement(SelectedIcon, { width, height, fill: color }) : null}
		</span>
	);
};

export default Icon;