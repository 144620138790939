import React, { useEffect, useRef } from 'react'; 
import Icon from '../media/icons';
import hljs from 'highlight.js';
import CopyButton from './Copybtn';

const BASE_URL = 'https://api.axipays.com';

function Code({ codeHeader, codeBody, className, customRender }) {
    const codeRef = useRef(null);

    useEffect(() => {
        if (codeRef.current && !customRender) {
            hljs.highlightBlock(codeRef.current);
        }
    }, [codeBody, customRender]);

    const handleCopy = () => {
        // Combine {{base-url}} with the rest of the URL for copying
        const mergedCode = `{{base-url}}${codeBody.replace(/{{base-url}}/g, '')}`;
        const processedCode = mergedCode.replace(/{{base-url}}/g, BASE_URL); // Replace {{base-url}} with actual URL

        navigator.clipboard.writeText(processedCode).then(
            // () => {
            //     alert('Code copied to clipboard!');
            // },
            (err) => {
                console.error('Failed to copy text: ', err);
            }
        );
    };

    return (
        <div className='codeBlock'>
            <p className='codeBlock-heading'>{codeHeader}</p>
            <div className='codyBody'>
                <div className='codeBlock-header'>
                    <div className='dummy-threeDots'>
                        <div className='dot1'></div>
                        <div className='dot2'></div>
                        <div className='dot3'></div>
                    </div>
                    <div className='codeBlock-header-Actions' onClick={handleCopy}>
                        <CopyButton />
                    </div>
                </div>

                <div className='codeBlock-body'>
                    {customRender ? (
                        <pre>
                            <code className='endpoint'>
                                <span className='get-body'>POST</span>
                                <span className='saparator'>|</span>
                                {/* Display {{base-url}} and the rest of the URL separately */}
                                <span className='base-url'>{`{{base-url}}`}</span>
                                <span className='url'>
                                    {codeBody.replace(/{{base-url}}/g, '')}
                                </span>
                            </code>
                        </pre>
                    ) : (
                        <pre>
                            <code ref={codeRef} className={className}>
                                {codeBody}
                            </code>
                        </pre>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Code;
